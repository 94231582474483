import React from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Alert, Table, Spinner } from "react-bootstrap";
import { cadastroService, inscricoesCursosService } from '../../../_services';
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { formatDate, formatFone, formatMoney, formatCPF, parseDate, handleAuthResponse, handleResponse } from '../../../_helpers';
import toastr from 'toastr';

export function InscricaoCursoRegional({ props }) {
    const [loading, setLoading] = React.useState(false);
    const [inscricao, setInscricao] = React.useState({
        cel: '',
        cep: '',
        cidade: '',
        compareceu: 0,
        compl: '',
        cpf: '',
        crf: '',
        curso: '',
        data: '',
        email: '',
        ender: '',
        enderInst: '',
        fila: '',
        fone: '',
        foneInst: '',
        formacao: '',
        instituicao: '',
        nome: '',
        obs: '',
        origem: '',
        pgto: '',
        regional: '',
        status: '',
        tipo: '',
        tipoInstituicao: '',
        uf: '',
        valor: '',
    });
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const params = useParams();

    React.useEffect(function () {
        if (params.id !== undefined) {
            const ID = params.id;

            inscricoesCursosService.getById(ID).then(r => handleAuthResponse(r, navigate))
                .then(data => {
                    Object.keys(data).forEach(key => (inscricao[key] !== undefined && (data[key] === undefined)) ? data[key] = '' : {});

                    setInscricao(data);
                    setInscricao((a) => {
                        return a = {
                            ...a, data: formatDate(data.data)
                            , pgto: formatDate(data.pgto)
                            , fila: a.fila === 0 ? 0 : 1 // maior que 0 -> 1
                        }
                    });
                }).catch(ex => {
                    toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
                });
        }
    }, [params.id]);

    const handleSubmit = function (event) {
        event.preventDefault();

        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('.main-panel').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        } 
        setLoading(true);
        var req = {
            ...inscricao,
            data: inscricao.data && parseDate(inscricao.data),
            pgto: inscricao.pgto && parseDate(inscricao.pgto), 
        };
        inscricoesCursosService.update(req).then(r => handleResponse(r)).then(res => {
            toastr.success('Dados atualizados com sucesso.');
            setLoading(false);
            navigate(-1);
        }).catch(function () {
            setLoading(false);
            toastr.error('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
        });

    }

    const handleChange = function (event) {
        // Apenas o admin altera
        if (props.usuario.nivelAcesso >= 6) {
            const value = event.target.type === 'checkbox' ? Number(event.target.checked) : event.target.value;
            var name = event.target.name;
            setInscricao((a) => {
                return a = { ...a, [name]: value }
            });
        }
    }

    const catInscr = function () {
        if (inscricao.status < 0) { return "NÃO ASSOCIADO"; }
        else if (inscricao.status === 0) { return "ASSOCIADO INATIVO"; }
        else { return "ASSOCIADO ATIVO"; }
    } 

    return (inscricao && <>
        <div className="register-body">
            <div className="register-wrapper">
                <Container className="vertical-center">
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title className="register-form-title">{inscricao.curso}</Card.Title>
                                    <div style={{ float: 'right' }}>
                                        <NavLink className="btn btn-primary btn-sm"
                                            to={`/admin/cursos-regionais/inscricoes/?uf=${searchParams.get('uf')}&data=${searchParams.get('data')}`}>
                                            Voltar
                                        </NavLink>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                        {inscricao && <>
                                            <Table responsive className=" table-striped small table-small">
                                                <tbody >
                                                    <tr>
                                                        <td>Data: </td>
                                                        <td>{inscricao.data}</td>
                                                        <td>SBRAFH: </td>
                                                        <td colSpan="2">{inscricao.regional}</td>
                                                    </tr>
                                                    <tr >
                                                        <td >Nome: </td>
                                                        <td colSpan="3">{inscricao.nome}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>CPF: </td>
                                                        <td>{formatCPF(inscricao.cpf)}</td>
                                                        <td>CRF: </td>
                                                        <td colSpan="2">{inscricao.crf}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Endereço: </td>
                                                        <td colSpan="3">
                                                            {inscricao.ender} {inscricao.compl} <br />
                                                            {inscricao.bairro} - {inscricao.cep} <br />
                                                            {inscricao.cidade} / {inscricao.uf} <br />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>E-mail: </td>
                                                        <td colSpan="3">{inscricao.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DDD/Telefone: </td>
                                                        <td>{formatFone(inscricao.fone)}</td>
                                                        <td>Celular: </td>
                                                        <td colSpan="2">{formatFone(inscricao.cel)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Instituição tipo: </td>
                                                        <td colSpan="3">{inscricao.tipoInstituicao}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Instituição: </td>
                                                        <td colSpan="3">{inscricao.instituicao}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Endereço: </td>
                                                        <td colSpan="3">{inscricao.enderInst}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>DDD/Fone: </td>
                                                        <td colSpan="3">{formatFone(inscricao.foneInst)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Como soube: </td>
                                                        <td colSpan="3">{inscricao.origem}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Table responsive className=" table-striped small table-small">
                                                <tbody >
                                                    <tr >
                                                        <td>Inscrição ID: </td>
                                                        <td colSpan="2">{inscricao.id}</td>
                                                        <td>Tipo Inscrição: </td>
                                                        <td>
                                                            <Form.Select id="tipo" name="tipo" className="form-control"
                                                                value={inscricao.tipo} onChange={handleChange} required>
                                                                <option value="PARTICIPANTE">PARTICIPANTE</option>
                                                                <option value="PALESTRANTE">PALESTRANTE</option>
                                                                <option value="COMISSÃO">COMISSÃO</option>
                                                                <option value="ORGANIZAÇÃO">ORGANIZAÇÃO</option>
                                                            </Form.Select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cat. Inscrição: </td>
                                                        <td colSpan="2">{catInscr()}</td>
                                                        <td>Valor R$: </td>
                                                        <td colSpan="2">
                                                            <Form.Control maxLength="10" type="text" id="valor" name="valor"
                                                                value={formatMoney(inscricao.valor)} onChange={handleChange} required>
                                                            </Form.Control>
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td>Formação: </td>
                                                        <td colSpan="2">{inscricao.formacao}</td>
                                                        <td>Status: </td>
                                                        <td>
                                                            <Form.Select id="fila" name="fila" className="form-control" onChange={handleChange}
                                                                value={inscricao.fila} required>
                                                                <option value="0">ATIVA</option>
                                                                <option value="1">FILA DE ESPERA</option>
                                                            </Form.Select>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Observações: </td>
                                                        <td colSpan="3">
                                                            <Form.Control placeholder="Observações" maxLength="254" type="text" id="obs" name="obs"
                                                                value={inscricao.obs} onChange={handleChange}></Form.Control>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Alert variant="secondary">
                                                Caso a inscrição esteja na fila, basta selecionar ATIVA na caixa Status acima e o sistema irá efetivá-la. Se a inscrição
                                                tiver valor a pagar, este deverá ser pago no local do evento e é necessário entrar em contato com a pessoa para
                                                confirmar a presença antes da efetivação dessa inscrição.
                                            </Alert>
                                            <Row>
                                                <Col lg={3}>
                                                    <label>Data Pagamento: </label>
                                                    <MaskedInput type="text" className="form-control" name="pgto" id="pgto" placeholder="01/01/0000"
                                                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                        value={inscricao.pgto} onChange={handleChange}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                </Col>
                                                <Col lg={3}>
                                                    <label> </label><br />
                                                    <Form.Check type="checkbox" name="compareceu" id="compareceu"
                                                        checked={inscricao.compareceu} label="Compareceu" onChange={handleChange} >
                                                    </Form.Check>
                                                </Col>
                                                {   /* Apenas o admin altera */  
                                                    props.usuario.nivelAcesso >= 6 && <>
                                                    <Col lg={3}>
                                                        <label> </label><br />
                                                        <Button id="btSend" name="btSend" className="btn btn-sm btn-success" color="success" type="submit">
                                                            Atualizar
                                                            {loading &&
                                                                <> &nbsp;
                                                                    <Spinner animation="border" role="status" size="sm">
                                                                        <span className="visually-hidden">Carregando...</span>
                                                                    </Spinner> </>}
                                                        </Button>
                                                    </Col> </>}
                                            </Row>
                                        </>}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}