import React from "react";
import { useNavigate, Route, Routes } from "react-router-dom";

//import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/animate.min.css";
import "assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";

import AdminNavbar from "components/Navbars/AdminNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import DirRegionalSidebar from "components/Sidebar/DirRegionalSidebar.js";
import adminDashboardRoutes from "components/AdminDashboardRoutes.js";
import dirRegionalDashboardRoutes from "components/DirRegionalDashboardRoutes.js";
import { authenticationService, cadastroService } from "../_services";
import { handleAuthResponse } from '../_helpers';

import sidebarImage from "assets/img/sidebar-2.jpg";

function AdminLayout() {

  const [image] = React.useState(sidebarImage);
  const [color] = React.useState("azure");
  const [hasImage] = React.useState(true);
  const [usuario, setUsuario] = React.useState({});

  const navigate = useNavigate();
  const mainPanel = React.useRef(null);

  const getRoutes = (routes, usuario) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={<prop.component props={{usuario: usuario}}/>} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const rotas = function () {
    if (usuario.nivelAcesso === 6) {
      return (<><Routes>{getRoutes(adminDashboardRoutes, usuario)}</Routes></>);
    } else if (usuario.nivelAcesso === 3 ||(usuario.nivelAcesso === 5)) {
      return (<><Routes>{getRoutes(dirRegionalDashboardRoutes, usuario)}</Routes></>);
    }
  }

  React.useEffect(() => {
    const usuario = authenticationService.loggedUser;

    cadastroService.getById(usuario.id).then(r => handleAuthResponse(r, navigate)).then(u => {
      setUsuario(u);
    });

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, []);
  return (usuario &&
    <>
      <div className="wrapper">
        {usuario.nivelAcesso === 6 &&
          <AdminSidebar color={color} image={hasImage ? image : ""} routes={adminDashboardRoutes} usuario={usuario} />
        }
        {(usuario.nivelAcesso === 3 || usuario.nivelAcesso === 5) &&
          <DirRegionalSidebar color={color} image={hasImage ? image : ""} routes={dirRegionalDashboardRoutes} usuario={usuario} />
        }
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar usuario={usuario} />
          <div className="content">
            {rotas()}
          </div>
          <AdminFooter />
        </div>
      </div>
    </>
  );
}

export default AdminLayout;
