import React from "react";
import { Card, Table, Container, Row, Col, Form, Pagination, Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { eventosRegionaisService } from "../../../_services";
import { formatDate } from "../../../_helpers";
import toastr from 'toastr';

export default function IndexEventosRegionais({ props }) {
  const [pages, setPages] = React.useState([]);
  const [eventosRegionais, setEventosRegionais] = React.useState([]);
  const [filtro, setFiltro] = React.useState({
    regional: '',
    tituloEvento: '',
    pageIndex: 0,
    pageSize: 50,
    totalRows: 0,
  });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(function () {
    if (props.usuario.nivelAcesso < 6) {
      filtro.regional = props.usuario.regional;
      setFiltro((f) => { return f = { ...f, uf: props.usuario.regional } });
    }
  }, [props.usuario.regional]);

  React.useEffect(function () {
    aplicarFiltros();
  }, []);

  React.useEffect(function () { 
    let items = [];
    const totalPagesMax = Math.min(filtro.totalPages, filtro.pageIndex + 11);
    items.push(<Pagination.First key="first" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = 0; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Prev key="prev" disabled={filtro.pageIndex + 1 === 1} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex - 1; 
        aplicarFiltros();
      }} />);
    for (let number = Math.max(1, filtro.pageIndex - 6); number <= totalPagesMax; number++) {
      items.push(
        <Pagination.Item key={number} active={number - 1 === filtro.pageIndex} onClick={
          () => {
            filtro.pageIndex = number - 1; 
            aplicarFiltros();
          }
        }>
          {number}
        </Pagination.Item>,
      );
    }
    items.push(<Pagination.Next key="next" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.pageIndex + 1; 
        aplicarFiltros();
      }} />);
    items.push(<Pagination.Last key="last" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
      () => {
        filtro.pageIndex = filtro.totalPages - 1; 
        aplicarFiltros();
      }} />);
    setPages(items);
  }, [filtro.pageIndex, filtro.totalPages]);


  const aplicarFiltros = function () {
    setLoading(true);
    eventosRegionaisService.filter(filtro).then((res) => {
      setEventosRegionais(res.data); 
      filtro.totalPages = (res.totalPages);
      filtro.totalRows = (res.totalRows); 
    }).catch((ex) => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    }).then(function () {
      setLoading(false);
    });
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="10">
            <Card>
              {loading &&
                <div className="loading-overlay d-flex justify-content-center align-text-center" >
                  <div className="loading-spinner "></div>
                  <br />
                  <div className="loading-text">Carregando...</div>
                </div>
              }
              <Card.Header>
                <Card.Title as="h4">Proposta para Eventos Regionais</Card.Title>
                <div style={{ float: 'right' }}>
                  <NavLink className="btn btn-success btn-sm" to="/admin/eventos-regionais/novo">
                    Inserir Registro
                  </NavLink>
                </div>
              </Card.Header>
              <Card.Body>
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">ID</th>
                      <th className="border-0">Regional</th>
                      <th className="border-0">Título/Data(s)/Horário(s)</th>
                      <th className="border-0">Enviada</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      eventosRegionais &&
                      eventosRegionais.map(e => (
                        <tr key={e.id}>
                          <td>
                            <NavLink className="btn-simple btn-link p-1 info" to={'/admin/eventos-regionais/' + e.id} >
                              {String(e.id).padStart(6, '0')}
                            </NavLink>
                          </td>
                          <td>{e.regional}</td>
                          <td>{e.tituloEvento}</td>
                          <td>{formatDate(e.enviado)}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Pagination size="sm">{pages}</Pagination>
              </Card.Body>
            </Card>
            <Alert variant="secondary">
              {
                (eventosRegionais.length === 0) && <>
                  <b>Nenhum resultado encontrado.</b> </>
              }
              {
                (eventosRegionais.length > 0) && <>
                  <b>Exibindo resultados: {filtro.pageSize * (filtro.pageIndex) + 1} a {((filtro.pageIndex) * filtro.pageSize + Math.min(eventosRegionais.length, filtro.pageSize))} de {filtro.totalRows}.</b> </>
              }
            </Alert>
          </Col>
        </Row>
      </Container>
    </>
  );
}