import React from "react";
import { Card, Table, Container, Row, Col, Form, Pagination, Alert } from "react-bootstrap";
import { format } from 'date-fns';
import { NavLink, useSearchParams, useLocation } from "react-router-dom";
import { inscricoesCursosService } from "../../../_services";
import { formatDate, displayMoney, handleResponse } from "../../../_helpers";
import toastr from 'toastr';

export default function IndexInscricoesCursos({ props }) {
    const [pages, setPages] = React.useState([]);
    const [inscricoes, setInscricoes] = React.useState([]);
    const [filaEspera, setFilaEspera] = React.useState([]);
    const [titulo, setTitulo] = React.useState([]);
    const [regional, setRegional] = React.useState([]);
    const [filtro, setFiltro] = React.useState({
        regional: '',
        data: '',
        pageIndex: 0,
        pageSize: 200,
        totalRows: 0,
    });
    const [loading, setLoading] = React.useState(false);

    const [searchParams] = useSearchParams();
    const location = useLocation();

    React.useEffect(function () {
        aplicarFiltros();
    }, [location.key]);

    const aplicarFiltros = function () {
        const UF = searchParams.get('uf');
        const data = searchParams.get('data');

        if (UF && data) {
            filtro.regional = String(UF).toUpperCase();
            filtro.data = format(new Date(String(data)), 'MM/dd/yyyy');
        } else { return; }

        setLoading(true);

        // Carrega inscrições e fila de espera
        inscricoesCursosService.filter(filtro).then(r => handleResponse(r)).then((res) => {
            
            let inscr = res.data.filter(i => i.fila === 0);
            let fila = res.data.filter(i => i.fila > 0).sort((a, b) => a.idInscricao - b.idInscricao);

            setInscricoes(inscr);
            setFilaEspera(fila);

            setTitulo(res.data[0].curso);
            setRegional(res.data[0].regional);
            filtro.totalPages = (res.totalPages);
            filtro.totalRows = (res.totalRows);
        }).catch((ex) => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        }).then(function () {
            setLoading(false);
        });
    }

    React.useEffect(function () {
        let items = [];
        const totalPagesMax = Math.min(filtro.totalPages, filtro.pageIndex + 11);
        items.push(<Pagination.First key="first" disabled={filtro.pageIndex + 1 === 1} onClick={
            () => {
                filtro.pageIndex = 0;
                aplicarFiltros();
            }} />);
        items.push(<Pagination.Prev key="prev" disabled={filtro.pageIndex + 1 === 1} onClick={
            () => {
                filtro.pageIndex = filtro.pageIndex - 1;
                aplicarFiltros();
            }} />);
        for (let number = Math.max(1, filtro.pageIndex - 6); number <= totalPagesMax; number++) {
            items.push(
                <Pagination.Item key={number} active={number - 1 === filtro.pageIndex} onClick={
                    () => {
                        filtro.pageIndex = number - 1;
                        aplicarFiltros();
                    }
                }>
                    {number}
                </Pagination.Item>,
            );
        }
        items.push(<Pagination.Next key="next" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
            () => {
                filtro.pageIndex = filtro.pageIndex + 1;
                aplicarFiltros();
            }} />);
        items.push(<Pagination.Last key="last" disabled={filtro.pageIndex + 1 === filtro.totalPages || filtro.totalRows === 0} onClick={
            () => {
                filtro.pageIndex = filtro.totalPages - 1;
                aplicarFiltros();
            }} />);
        setPages(items);
    }, [filtro.pageIndex, filtro.totalPages]);

    const checkboxCompClick = function (event, inscrID) {
        if (props.usuario.nivelAcesso >= 3) { // 3 = Somente Regional
            var inscr = inscricoes.find(n => n.id === inscrID);
            let ins = [...inscricoes];
            ins[inscricoes.indexOf(inscr)].compareceu = inscr.compareceu === 1 ? 0 : 1;
            setInscricoes(ins);

            const upd = ins[inscricoes.indexOf(inscr)];
            inscricoesCursosService.update(upd).then(function () {
                toastr.success('Alterado com sucesso.');
            }).catch(function () {
                toastr.warning('Ocorreu um erro ao alterar. Tente novamente mais tarde.');
            });
        }
    }

    const totalRecebido = function () {
        var total = 0;
        inscricoes.filter(i => i.pgto).forEach(v => {
            total += v.valor
        });
        return total;
    }

    const totalPendente = function () {
        var total = 0;
        inscricoes.filter(i => !i.pgto).forEach(v => {
            total += v.valor
        });
        return total;
    }

    const createCheckBoxComp = function (inscr) {
        return inscr.compareceu ? (<input type="checkbox" key={inscr.id} onChange={(event) => { checkboxCompClick(event, inscr.id) }} value={'1'} checked />) :
            (<input type="checkbox" key={inscr.id} onChange={(event) => { checkboxCompClick(event, inscr.id) }} value={'1'} checked={false} />);
    } 

    const exportarExcel = function (event) {
        event.preventDefault();
        setLoading(true);
        const filtroReq = {
          ...filtro,
          pageIndex: 0,
          pageSize: 77777,
        };
        inscricoesCursosService.exportExcel(filtroReq)
          .then(resp => resp.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "Inscrições.xls";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }).catch((ex) => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
          }).then(function () {
            setLoading(false);
          });
      }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="10" className="ml-auto mr-auto" lg="12">
                        <Card className="striped-tabled-with-hover no-transition">
                            {loading &&
                                <div className="loading-overlay d-flex justify-content-center align-text-center" >
                                    <div className="loading-spinner "></div>
                                    <br />
                                    <div className="loading-text">Carregando...</div>
                                </div>
                            }
                            <Card.Header>
                                <Card.Title as="h4">{titulo} - Regional {regional}
                                <br/>   
                                <div>
                                <input id="btnExcel" name="btnExcel" type="image" src={require('../../../../src/assets/img/excel.png')}
                                    style={{ width: '35px', height: '35px', marginTop: '10px' }} title="Exportar para Excel" alt="Exportar para Excel" onClick={(event) => { exportarExcel(event) }} />
                                </div>
                                </Card.Title>
                                <hr />
                                {props.usuario.nivelAcesso >= 6 && <>
                                    <NavLink className="btn-success" to={`/admin/cursos-regionais/inscricao?uf=${searchParams.get('uf')}&data=${searchParams.get('data')}`}>
                                        Inserir Nova Inscrição
                                    </NavLink></>}

                                   
                            </Card.Header>
                            <Card.Body className="table-responsive px-0">
                                <Table responsive className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">Inscr.</th>
                                            <th className="border-0">Data</th>
                                            <th className="border-0">Tipo</th>
                                            <th className="border-0">Nome</th>
                                            <th className="border-0">Valor</th>
                                            <th className="border-0">Pgto.</th>
                                            <th className="border-0">Comp.</th>
                                            <th className="border-0">Obs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            inscricoes &&
                                            inscricoes.map(e => (
                                                <tr key={e.id}>
                                                    <td> {String(e.id).padStart(6, '0')}   </td>
                                                    <td>{formatDate(e.data)}</td>
                                                    <td>{e.tipo}</td>
                                                    <td>
                                                        <NavLink className="btn-simple" to={`/admin/cursos-regionais/inscricao/${e.id}?uf=${searchParams.get('uf')}&data=${searchParams.get('data')}`} >
                                                            {e.nome}
                                                        </NavLink>
                                                    </td>
                                                    <td>{displayMoney(e.valor)}</td>
                                                    <td>{formatDate(e.pgto)}</td>
                                                    <td>{createCheckBoxComp(e)}</td>
                                                    <td>{e.obs}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td colSpan="2" style={{ textAlign: 'right' }}><b>Recebido R$</b></td>
                                            <td>{displayMoney(totalRecebido())}</td>
                                            <td colSpan="2" style={{ textAlign: 'right' }}><b>Pendente R$</b></td>
                                            <td>{displayMoney(totalPendente())}</td>
                                            <td colSpan="2"></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Pagination size="sm">{pages}</Pagination>

                            </Card.Body>

                            <Alert variant="secondary">
                                {
                                    (inscricoes.length === 0) && <>
                                        <b>Nenhum resultado encontrado.</b> </>
                                }
                                {
                                    (inscricoes.length > 0) && <>
                                        <b>Exibindo resultados: {filtro.pageSize * (filtro.pageIndex) + 1} a {((filtro.pageIndex) * filtro.pageSize + Math.min(inscricoes.length, filtro.pageSize))} de {filtro.totalRows}.</b> </>
                                }
                            </Alert>

                            <Card.Header>
                                <Card.Subtitle>Fila de Espera </Card.Subtitle>
                            </Card.Header>  
                            <Card.Body className="px-0">
                                <Table responsive className="table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border-0">Inscr.</th>
                                            <th className="border-0">Data</th>
                                            <th className="border-0">Tipo</th>
                                            <th className="border-0">Nome</th>
                                            <th className="border-0">Valor</th>
                                            <th className="border-0">Pgto.</th> 
                                            <th className="border-0">Obs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            filaEspera &&
                                            filaEspera.map(e => (
                                                <tr key={e.id}>
                                                    <td> {String(e.id).padStart(6, '0')}   </td>
                                                    <td>{formatDate(e.data)}</td>
                                                    <td>{e.tipo}</td>
                                                    <td>
                                                        <NavLink className="btn-simple" to={`/admin/cursos-regionais/inscricao/${e.id}?uf=${searchParams.get('uf')}&data=${searchParams.get('data')}`} >
                                                            {e.nome}
                                                        </NavLink>
                                                    </td>
                                                    <td>{displayMoney(e.valor)}</td>
                                                    <td>{formatDate(e.pgto)}</td> 
                                                    <td>{e.obs}</td>
                                                </tr>
                                            ))
                                        } 
                                    </tbody>
                                </Table>  
                            </Card.Body> 
                        </Card> 
                    </Col>
                </Row>
            </Container>
        </>
    );
}