import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { regionaisService, cursosRegionaisService } from "../../_services";
import { Nav, NavDropdown } from "react-bootstrap";
import { formatDate } from '../../_helpers';
import toastr from 'toastr';
import { format } from "date-fns";

function AdminSidebar({ color, image, routes }) {
  const location = useLocation();
  const [regionais, setRegionais] = React.useState([]);
  const [cursosRegionais, setCursosRegionais] = React.useState([]);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };


  React.useEffect(function () {
    regionaisService.list().then(data => {
      setRegionais(data);
    }).catch(() => {
      toastr.error('Erro ao carregar Regionais, por favor tente novamente.');
    });
  }, []);

  const loadRegionais = function (regUF) {
    cursosRegionaisService.filter({ regional: regUF, pageIndex: 0, pageSize: 10000000 }).then(res => {
      setCursosRegionais(res.data);
    }).catch(() => {
      toastr.error('Erro ao carregar Regionais, por favor tente novamente.');
    });
  }

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
         
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <a href="/admin">
            <div className="simple-text logo-mini mx-1" >
              <span className="fa fa-wrench" />
            </div>
          </a>
          <a href="/admin" className="simple-text">
            Administração
          </a>
        </div>
        <Nav>
        <li> <a href="https://webmail-seguro.com.br/sbrafh.org.br" className="nav-link" target="_blank">Webmail</a></li>

          {routes.map((prop, key) => {
            if (!prop.redirect && prop.sidebar)
              return (
                <li className={activeRoute(prop.layout + prop.path)} key={key}    >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            return null;
          })}

          {
            regionais && regionais.map(r => {
              return (
                <li key={r.id}>
                  <NavDropdown  key={`d-${r.id}`} title={"Regional - " + String(r.uf).toUpperCase()} id="nav-dropdown" onClick={() => loadRegionais(String(r.uf).toUpperCase())} >
                    <NavDropdown.Item key={'asf' + r.id} as={NavLink} to={`/admin/associados?uf=${r.uf}`}>
                      {`Lista de Associados (${String(r.uf).toUpperCase()})`}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {cursosRegionais && cursosRegionais.map((rr) => {
                      return (
                        <NavDropdown.Item key={`evv${rr.id}`} as={NavLink} to={`/admin/cursos-regionais/inscricoes/?uf=${rr.regional}&data=${format(new Date(rr.data), 'MM/dd/yyyy')}`} >
                          Evento - {formatDate(rr.data)}
                        </NavDropdown.Item>);
                    })}
                  </NavDropdown>
                </li>
              );
            })
          }
        </Nav>
      </div>
    </div>
  );
}

export default AdminSidebar;
