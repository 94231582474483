import React from "react";
import { Button, Card, Table, Container, Row, Col, Form, Pagination } from "react-bootstrap";
import { ModalConfirm } from "../../../components/Modals";
import { NavLink } from "react-router-dom";
import { cursosRegionaisService, inscricoesCursosService } from "../../../_services";
import { formatDate } from "../../../_helpers";
import toastr from 'toastr';

export default function IndexCursosRegionais() {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pages, setPages] = React.useState([]);
  const [cursosRegionais, setCursosRegionais] = React.useState([]); 
  const [inscr, setInscr] = React.useState(false);
  const [filtro] = React.useState({
    tituloEvento: '',
    regional: '',
    pageIndex: 0,
    pageSize: 50
  });

  const aplicarFiltros = function () { 
    cursosRegionaisService.filter(filtro).then((res) => {
      setCursosRegionais(res.data);

      let items = [];
      for (let number = 1; number <= 5; number++) {
        items.push(
          <Pagination.Item key={number} active={number === currentPage}>
            {number}
          </Pagination.Item>,
        );
      }
      setPages(items);  
    }).then(a => {
      cursosRegionais.map((ce, i) => {
        const r = {
          regional: ce.regional,
          data: ce.data,
        };
        inscricoesCursosService.count(r).then(res => {
          let cursos = [...cursosRegionais];
          cursos[i].inscritos = res;
          setCursosRegionais(cursos); 
        });
      });
      setInscr(true);
    }).catch((ex) => {
      toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
    });
  } 
 
  React.useEffect(function () {
    aplicarFiltros(); 
  }, [inscr]);

  return (
    <>
    <ModalConfirm message="Deseja excluir o registro?" showModal={showConfirm} onHide={() => {setShowConfirm(false)}} />
      <Container fluid>
        <Row>
          <Col md="10">
            <Card className="striped-tabled-with-hover no-transition">
              <Card.Header>
                <Card.Title as="h4">Agenda de Eventos Regionais</Card.Title>
                <div style={{ float: 'right' }}>
                  <NavLink className="btn btn-success btn-sm" to="/admin/cursos-regionais/novo">
                    Novo Registro
                  </NavLink>
                </div>
              </Card.Header>
              <Card.Body className="table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Data</th>
                      <th className="border-0">Reg.</th>
                      <th className="border-0">Título</th>
                      <th className="border-0">Data Máxima</th>
                      <th className="border-0">Del.</th>
                      <th className="border-0">Inscr.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cursosRegionais &&
                      cursosRegionais.map((e, i) => (
                        <tr key={e.id}>
                          <td>
                            <NavLink className="btn-simple btn-link p-1 info" to={'/admin/cursos-regionais/' + e.id} >
                              {formatDate(e.data)}
                            </NavLink>
                          </td>
                          <td>{e.regional}</td>
                          <td>{e.titulo}</td>
                          <td>{formatDate(e.dataMaxima)}</td>
                          <td> <Button className="btn-simple btn-link p-1" type="button" variant="danger" onClick={() => {setShowConfirm(true)}} >
                            <i className="pe-7s-close"></i>
                          </Button></td>
                          <td>{e.inscritos}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <Pagination size="sm">{pages}</Pagination>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}