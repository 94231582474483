import React from 'react';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';
import { Button, Container, Row, Col, Card, Form, Spinner, InputGroup, Table } from "react-bootstrap";
import { authenticationService, cursosRegionaisService, regionaisService, fileService, certificadosService } from '../../../_services';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { formatDate, parseDate, parseDateISO, formatMoney } from '../../../_helpers';
import { ModalMsg } from '../../../components/Modals'
import toastr from 'toastr';
import { handleAuthResponse, handleResponse } from '../../../_helpers/handle-response.js';

export function CriarCursoRegional() {
    const [editing, setEditing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [regionais, setRegionais] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [cursoRegional, setCursoRegional] = React.useState({
        regional: '',
        titulo: '',
        data: '',
        dataMaxima: '',
        valorAssProf: '0',
        valorAssEstG: '0',
        valorNAssProf: '175',
        valorNAssEstG: '75',
        exclusivo: '0',         // = 1: exclusivo para associados ativos
        estudantes: '0',        // = 1: exclusivo para profissionais
        vagas: '50',
        participante: 'Certificamos que #nome participou como ouvinte do evento: título, realizado em xx de xx de 2014 no endereço, com carga horária de x horas.',
        palestrante: 'Certificamos que #nome participou como palestrante do evento: título, realizado em xx de xx de 2014 no endereço, com carga horária de x horas.',
        comissao: 'Certificamos que #nome participou como membro da comissão organizadora do evento: título, realizado em xx de xx de 2014 no endereço, com carga horária de x horas.',
        banner: '',
        folder: '',
        certificado: '',
        mgTop: '350px',
        mgRight: '0px',
        mgLeft: '105px',
        width: '900px',
    });

    const navigate = useNavigate();
    const params = useParams();
    const getRegionais = function () {
        regionaisService.list().then(data => {
            setRegionais(data)
        }).catch((ex) => {
            toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
        });
    }

    React.useEffect(function () {
        getRegionais();
        if (params.id !== undefined) {
            const ID = params.id;
            cursosRegionaisService.getById(ID).then(r => handleAuthResponse(r, navigate))
                .then(data => {
                    Object.keys(data).forEach(key => (cursoRegional[key] !== undefined && (data[key] === undefined || data[key] === null)) ? data[key] = '' : {});

                    setCursoRegional(data);
                    setCursoRegional((a) => {
                        return a = { ...a, data: formatDate(data.data), dataMaxima: formatDate(data.dataMaxima) }
                    });
                    setEditing(true);
                }).catch(ex => {
                    toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
                });
        }
    }, [params.id]);

    const handleChange = function (event) {
        const value = event.target.type === 'checkbox' ? Number(event.target.checked) : event.target.value;
        var name = event.target.name;
        setCursoRegional((a) => {
            return a = { ...a, [name]: value }
        });
    }

    const handleSubmit = function (event) {

        event.preventDefault();
        // Valida campos
        var form = document.getElementById('cadastro');
        if (form.checkValidity() === false) {
            event.stopPropagation();
            form.classList.add('was-validated');
            $('.main-panel').animate({ scrollTop: $('.was-validated .form-control:invalid').offset().top - 200 }, 'slow');
            return;
        }

        setLoading(true);
        if (editing) {
            var req = {
                ...cursoRegional,
                data: parseDate(cursoRegional.data),
                dataMaxima: parseDate(cursoRegional.dataMaxima),
            };

            cursosRegionaisService.update(req).then(r => handleAuthResponse(r, navigate)).then(res => {
                toastr.success('Dados atualizados com sucesso.');
                setLoading(false);
                navigate('/admin/cursos-regionais/');
            }).catch(function () {
                setLoading(false);
                toastr.error('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
            });
        } else { 
            var req = {
                ...cursoRegional,
                data: parseDate(cursoRegional.data),
                dataMaxima: parseDate(cursoRegional.dataMaxima),
            };

            cursosRegionaisService.create(req).then(r => handleAuthResponse(r, navigate)).then((res) => {
                toastr.success('Dados salvos com sucesso.');
                setLoading(false);
                navigate('/admin/cursos-regionais/');
            }).catch(function () {
                setLoading(false);
                toastr.error('Ocorreu um erro ao salvar os dados. Por favor tente novamente.');
            });
        }
    }

    React.useEffect(function () {
    }, [cursoRegional.certificado]);

    const fileChanged = function (event) {
        const f = document.getElementById('fileCertificado').files[0];

        if (/\.(jpe?g|png|gif)$/i.test(f.name) === false) {
            setShowModal(true);
            return;
        }

        const req = {
            path: '/sbrafh/front/certificados/',
            file: f
        }
        setLoading(true)

        fileService.upload(req).then(js => {
            setLoading(false);
            toastr.success(`Arquivo '${f.name}' enviado com sucesso.`);
        }).catch((e) => {
            console.log(e);
            toastr.error('Erro ao enviar o arquivo de certificado.');
            setLoading(false);
        });

        setCursoRegional((a) => {
            return { ...a, certificado: f.name }
        });
    }

    const previewCert = function (event) {
        event.preventDefault();
        setLoading(true); 
        certificadosService.preview(cursoRegional.id)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = "Certificado.pdf";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }).catch((ex) => {
                toastr.error('Ocorreu um erro ao carregar os dados. Por favor tente novamente.');
            }).then(function () {
                setLoading(false);
            });
    }

    return (<>
        <div className="register-body">
            <div className="register-wrapper">
                <Container className="vertical-center">
                    <ModalMsg message="O arquivo deve ser no formato GIF, PNG ou JPG!" title="Certificado" msgClass="alert alert-warning" showModal={showModal}
                        onHide={() => setShowModal(false)} onConfirm={() => setShowModal(false)} backdrop="static" />
                    <Row>
                        <Col className="ml-auto mr-auto" lg="12">
                            <Card className="card-register">
                                <Card.Header style={{ color: 'black' }}>
                                    <Card.Title className="register-form-title">Eventos Regionais
                                        <div style={{ float: 'right' }}>
                                            <NavLink className="btn btn-primary btn-sm" to="/admin/cursos-regionais/">
                                                Voltar
                                            </NavLink>
                                        </div></Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form id="cadastro" name="cadastro" method="POST" noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Col className="pr-1" md={12}>
                                                <label>Regional: </label>
                                                <Form.Select id="regional" name="regional" className="form-control"
                                                    value={cursoRegional.regional} onChange={handleChange}>
                                                    <option value="-1">Selecione</option>
                                                    {regionais.map(e => (
                                                        <option key={e.id} value={String(e.uf).toUpperCase()}>{String(e.uf).toUpperCase()} - {e.regional}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={12}>
                                                <label>Título do Evento:</label>
                                                <Form.Control type="text" placeholder="Título" maxLength="254" id="titulo" name="titulo" required
                                                    value={cursoRegional.titulo}
                                                    onChange={handleChange} />
                                                <div className="invalid-feedback">
                                                    Por favor informe o título!
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4}>
                                                <label>Data Prevista para Início: </label>
                                                <MaskedInput type="text" className="form-control" name="data" id="data" required placeholder="01/01/0000"
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                    value={cursoRegional.data} onChange={handleChange}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />
                                                <div className="invalid-feedback">
                                                    Por favor preencha a data.
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <label>Data Máxima para Inscrições online: </label>
                                                <MaskedInput type="text" className="form-control" name="dataMaxima" id="dataMaxima" required placeholder="01/01/0000"
                                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                    value={cursoRegional.dataMaxima} onChange={handleChange}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />
                                                <div className="invalid-feedback">
                                                    Por favor preencha a data.
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <label>Vagas Disponíveis: </label>
                                                <input type="text" id="vagas" name="vagas" className="form-control" maxLength="8" required
                                                    value={cursoRegional.vagas} onChange={handleChange}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }} />
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col md={12}>
                                                <label>Valores para inscrição online</label>
                                            </Col>
                                            <Row>
                                                <Col md={3}>
                                                    <label>Associado Profissional </label>
                                                    <input type="text" id="valorAssProf" name="valorAssProf" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                        value={formatMoney(cursoRegional.valorAssProf)} onChange={handleChange} />
                                                </Col>
                                                <Col md={3}>
                                                    <label> Associado Est. Grad. Farmácia</label>
                                                    <input type="text" id="valorAssEstG" name="valorAssEstG" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                        value={formatMoney(cursoRegional.valorAssEstG)} onChange={handleChange} />
                                                </Col>
                                                <Col md={3}>
                                                    <label>Não Associado Profissional </label>
                                                    <input type="text" id="valorNAssProf" name="valorNAssProf" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                        value={formatMoney(cursoRegional.valorNAssProf)} onChange={handleChange} />
                                                </Col>
                                                <Col md={3}>
                                                    <label> Não Associado Est. Grad. Farmácia</label>
                                                    <input type="text" id="valorNAssocEstG" name="valorNAssEstG" className="form-control" maxLength="8" required aria-describedby="numSm"
                                                        value={formatMoney(cursoRegional.valorNAssEstG)} onChange={handleChange} />
                                                </Col>
                                            </Row>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Row>
                                                    <Col lg="6" sm="6">
                                                        <Form.Check inline type="checkbox" name="exclusivo" id="exclusivo"
                                                            checked={(cursoRegional.exclusivo)} label="Evento exclusivo para Associados Ativos" onChange={handleChange} >
                                                        </Form.Check>
                                                    </Col>
                                                    <Col lg="6" sm="6">
                                                        <Form.Check inline type="checkbox" name="estudantes" id="estudantes"
                                                            checked={(cursoRegional.estudantes)} label="Evento exclusivo para Profissionais" onChange={handleChange} >
                                                        </Form.Check>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <label>Texto Participante:</label>
                                                <Form.Control cols="80" placeholder="" rows="3" as="textarea" id="participante" name="participante"
                                                    value={cursoRegional.participante} onChange={handleChange}></Form.Control>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <label>Texto Palestrante:</label>
                                                <Form.Control cols="80" placeholder="" rows="3" as="textarea" id="palestrante" name="palestrante"
                                                    value={cursoRegional.palestrante} onChange={handleChange}></Form.Control>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <label>Texto Comissão:</label>
                                                <Form.Control cols="80" placeholder="" rows="3" as="textarea" id="comissao" name="comissao"
                                                    value={cursoRegional.comissao} onChange={handleChange}></Form.Control>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <label>Certificado do Evento (o formato pode ser: JPG, GIF ou PNG):</label> <br />
                                                {cursoRegional.certificado && <> Arquivo: <a target="_blank" rel="noreferrer" href={"https://sbrafh.com.br/certificados/" + cursoRegional.certificado}>{cursoRegional.certificado}</a> </>}

                                                <input className="form-control" type="file" id="fileCertificado" onChange={fileChanged}></input>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <label>Parâmetros de Impressão do Texto</label>
                                            </Col>
                                            <Row>
                                                <Col md={3}>
                                                    <label>Topo: </label>
                                                    <input type="text" id="mgTop" name="mgTop" className="form-control" maxLength="8" aria-describedby="numSm"
                                                        value={cursoRegional.mgTop} onChange={handleChange} />
                                                </Col>
                                                <Col md={3}>
                                                    <label>Direita: </label>
                                                    <input type="text" id="mgRight" name="mgRight" className="form-control" maxLength="8" aria-describedby="numSm"
                                                        value={cursoRegional.mgRight} onChange={handleChange} />
                                                </Col>
                                                <Col md={3}>
                                                    <label>Esquerda: </label>
                                                    <input type="text" id="mgLeft" name="mgLeft" className="form-control" maxLength="8" aria-describedby="numSm"
                                                        value={cursoRegional.mgLeft} onChange={handleChange} />
                                                </Col>
                                                <Col md={3}>
                                                    <label>Largura: </label>
                                                    <input type="text" id="width" name="width" className="form-control" maxLength="8" aria-describedby="numSm"
                                                        value={cursoRegional.width} onChange={handleChange} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <center>
                                                        {cursoRegional.id && cursoRegional.certificado &&
                                                            <> &nbsp; <Button variant="info" className="btn btn-sm" onClick={previewCert}>Preview</Button> </>}
                                                    </center>
                                                </Col>

                                            </Row>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col sm={12}>
                                                <center>
                                                    <Button id="btSend" name="btSend" className="btn-round btn-fill btn-primary" color="primary" type="submit">
                                                        Enviar
                                                        {loading &&
                                                            <> &nbsp;
                                                                <Spinner animation="border" role="status" size="sm">
                                                                    <span className="visually-hidden">Carregando...</span>
                                                                </Spinner> </>}
                                                    </Button>
                                                </center>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
    );
}