import { parse, format, parseISO } from 'date-fns';

export function parseDate (date) {
    try {
        if (date) {
            return parse(date, 'dd/MM/yyyy', new Date()).toISOString();
        }
    } catch {
        return '0001-01-01T00:00:00.000Z';
    }
}

export function parseDateISO (date) {
    try {
        if (date) {
            return parseISO(date);
        }
    } catch {
        return new Date(1, 1, 1);
    }
}

export function formatDate (date) {
    try {
        if (date) {
            return format(parseISO(date), 'dd/MM/yyyy');
        }
    } catch {
        return '01/01/0001';
    } 
}

export function formatFone(fone) {
    try {
        if (fone.length === 10) {
            return '(' + fone.substring(0, 2) + ') ' + fone.substring(2, 6) + '-' + fone.substring(6);
        } else if (fone.length === 11) {
            return '(' + fone.substring(0, 2) + ') ' + fone.substring(2, 3) + ' ' + fone.substring(3, 7) + '-' + fone.substring(7);
        }
        return fone;
    } catch {
        return fone;
    } 
}

export function formatCPF (cpf) {
    try {
        if (cpf.length === 11) {
            return cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring (6, 9) + '-' + cpf.substring (9);
        }  
    } catch {
        return cpf;
    } 
}

export function formatMoney (mo) {
    try { 
        return Number(mo).toFixed(2);
    } catch {
        return String(mo);
    } 
}

export function displayMoney (mo) {
    try { 
        return Number(mo).toFixed(2).replace('.', ',');
    } catch {
        return String(mo);
    } 
}